// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "B_tv d_gv d_cs";
export var heroHeaderCenter = "B_gw d_gw d_cs d_dw";
export var heroHeaderRight = "B_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "B_tw d_gr d_cw";
export var heroParagraphCenter = "B_gs d_gs d_cw d_dw";
export var heroParagraphRight = "B_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "B_tx d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "B_ty d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "B_tz d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "B_tB d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "B_tC d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "B_tD C_tD";
export var heroExceptionNormal = "B_tF C_tF";
export var heroExceptionLarge = "B_tG C_tG";
export var Title1Small = "B_tH C_tH C_tP C_tQ";
export var Title1Normal = "B_tJ C_tJ C_tP C_tR";
export var Title1Large = "B_tK C_tK C_tP C_tS";
export var BodySmall = "B_tL C_tL C_tP C_t6";
export var BodyNormal = "B_tM C_tM C_tP C_t7";
export var BodyLarge = "B_tN C_tN C_tP C_t8";