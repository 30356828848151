// extracted by mini-css-extract-plugin
export var articleWrapper = "r_nB";
export var searchResult = "r_ry r_nB";
export var line = "r_fk d_fk d_cY";
export var articleTitle = "r_rz d_cq";
export var articleText = "r_nC d_cs";
export var authorImage = "r_ln d_ln d_b7 d_Z d_ct";
export var authorText = "r_n2 d_ch d_ct";
export var textCenter = "r_dw d_dw";
export var searchWrapper = "r_rB d_bz d_bP d_dr d_cl d_Z";
export var searchInput = "r_rC d_cF d_w d_b4";
export var searchBtn = "r_rD d_b1 d_bC d_bP d_0 d_cD d_dB";
export var text = "r_pc d_cr";
export var elementWrapper = "r_my d_cl";
export var select = "r_rF d_w d_b4";
export var articleImageWrapper = "r_nT d_cs d_Z d_V";
export var articleImageWrapperIcon = "r_nV d_cs d_Z d_V";
export var articleImageWrapperColumns = "r_rG d_cs d_fh d_Z";
export var contentPadding = "r_rH d_cS";
export var otherPadding = "r_rJ d_cM";
export var paginationWrapper = "r_rK";
export var pagination = "r_rL";
export var disabledPagination = "r_rM";
export var breakMe = "r_rN";
export var activePage = "r_rP";
export var next = "r_rQ";
export var pages = "r_rR";
export var searchTitle = "r_rS d_cr";
export var categoryTitle = "r_rT d_cr d_Z";
export var searchText = "r_rV d_ct";
export var bold = "r_rW";
export var field = "r_rX";
export var dateTag = "r_nL d_l d_cr d_dq";
export var icon1 = "r_nM d_cP";
export var icon2 = "r_nN d_cP d_cJ";
export var tagBtn = "r_nP d_b1 d_cD d_dp d_dB";
export var linksWrapper = "r_n5 d_ch d_dw";
export var header = "r_nD d_w d_Z";
export var headerInner = "r_rY";
export var headerImage = "r_rZ d_bd d_0 d_8 d_7 d_4 d_9";
export var separator = "r_n1 d_cY";
export var resultImage = "r_r0";