// extracted by mini-css-extract-plugin
export var noMargin = "t_sl d_cc";
export var carouselCol = "t_sm d_cD";
export var carouselWrapper = "t_k6 d_k6 d_bz d_bD d_bP d_w d_H";
export var carouselImageWrapper = "t_k7 d_k7 d_H d_w";
export var carouselContainer = "t_sn d_cD";
export var carouselContainerFull = "t_sp d_dT";
export var carouselImg = "t_mR d_k8 d_w d_H d_bR";
export var imageTextWrapperLeft = "t_k1 d_k1 d_k0 d_0 d_bT";
export var imageTextWrapperCenter = "t_k2 d_k2 d_k0 d_0 d_bT";
export var imageTextWrapperRight = "t_k3 d_k3 d_k0 d_0 d_bT";
export var imageTextWrapperHidden = "t_kZ d_kZ";
export var imageAltSmall = "t_sq d_k4";
export var imageAltNormal = "t_sr d_k4";
export var imageAltLarge = "t_ss d_k4";
export var imageTextSmall = "t_st d_k5";
export var imageTextNormal = "t_sv d_k5";
export var imageTextLarge = "t_sw d_k5";