// extracted by mini-css-extract-plugin
export var campaignContainer = "s_r1 d_dW";
export var campaignContainerFull = "s_r2 d_dT";
export var wrapper = "s_r3 d_w";
export var leftWrapper = "s_r4 d_bz d_bN";
export var rightWrapper = "s_r5";
export var subtitle = "s_r6";
export var title = "s_r7";
export var text = "s_pc d_cs";
export var inlineWrapper = "s_r8 d_bC d_bG d_w";
export var btnsWrapper = "s_r9 s_r8 d_bC d_bG d_w d_bJ";
export var priceWrapper = "s_sb s_r8 d_bC d_bG d_w d_ch d_bP";
export var priceHeading = "s_sc";
export var disclaimerWrapper = "s_sd d_cD";
export var mediaSlot = "s_sf";
export var listWrapper = "s_sg d_bC d_bP d_w";
export var btnWrapper = "s_d2";
export var imageWrapper = "s_rj d_fg d_Z";
export var SmallSmall = "s_sh C_sh C_tP C_t9";
export var SmallNormal = "s_sj C_sj C_tP C_vb";
export var SmallLarge = "s_sk C_sk C_tP C_t7";
export var textLeft = "s_dv";
export var textCenter = "s_dw";
export var textRight = "s_dx";
export var alignLeft = "s_rd";
export var alignCenter = "s_bP";
export var alignRight = "s_rf";