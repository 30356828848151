// extracted by mini-css-extract-plugin
export var customText = "v_sx d_dv d_cs d_cg";
export var videoIframeStyle = "v_nS d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "v_rh d_cs d_cg d_Z";
export var customRow = "v_pb d_bD d_bf";
export var quoteWrapper = "v_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "v_nX d_H";
export var masonryImageWrapper = "v_n3";
export var title = "v_r7";
export var Title3Small = "v_sy C_sy C_tP C_tX";
export var Title3Normal = "v_sz C_sz C_tP C_tY";
export var Title3Large = "v_sB C_sB C_tP C_tZ";