// extracted by mini-css-extract-plugin
export var alignDiscLeft = "x_sP d_fp d_bG d_dv";
export var alignLeft = "x_rd d_fp d_bG d_dv";
export var alignDiscCenter = "x_sQ d_fq d_bD d_dw";
export var alignCenter = "x_bP d_fq d_bD d_dw";
export var alignDiscRight = "x_sR d_fr d_bH d_dx";
export var alignRight = "x_rf d_fr d_bH d_dx";
export var footerContainer = "x_sS d_dW d_bW";
export var footerContainerFull = "x_sT d_dT d_bW";
export var footerHeader = "x_kf d_kf";
export var footerTextWrapper = "x_sV d_w";
export var footerDisclaimerWrapper = "x_km d_km d_ck";
export var badgeWrapper = "x_sW d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "x_sX d_bz d_bJ d_bN d_bL";
export var wide = "x_sY d_cy";
export var right = "x_sZ d_bK";
export var line = "x_fk d_fl d_cv";
export var badgeDisclaimer = "x_s0 d_bC d_bP d_bJ";
export var badgeContainer = "x_s1 d_bz d_bH d_bP";
export var badge = "x_s2";
export var padding = "x_s3 d_c7";
export var end = "x_s4 d_bH";
export var linkWrapper = "x_s5 d_dB";
export var link = "x_mC d_dB";
export var colWrapper = "x_s6 d_cx";
export var consent = "x_h d_h d_bC d_bP";
export var disclaimer = "x_s7 d_bz d_bJ";
export var media = "x_s8 d_bx d_dy";
export var itemRight = "x_s9";
export var itemLeft = "x_tb";
export var itemCenter = "x_tc";
export var exceptionWeight = "x_td C_vf";