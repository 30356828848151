// extracted by mini-css-extract-plugin
export var carouselContainer = "J_sn d_w d_H d_bf d_Z";
export var carouselContainerCards = "J_wK J_sn d_w d_H d_bf d_Z";
export var carouselContainerSides = "J_wL d_w d_H d_Z";
export var prevCarouselItem = "J_wM d_w d_H d_0 d_k";
export var prevCarouselItemL = "J_wN J_wM d_w d_H d_0 d_k";
export var moveInFromLeft = "J_wP";
export var prevCarouselItemR = "J_wQ J_wM d_w d_H d_0 d_k";
export var moveInFromRight = "J_wR";
export var selectedCarouselItem = "J_wS d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "J_wT J_wS d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "J_wV J_wS d_w d_H d_Z d_bf";
export var nextCarouselItem = "J_wW d_w d_H d_0 d_k";
export var nextCarouselItemL = "J_wX J_wW d_w d_H d_0 d_k";
export var nextCarouselItemR = "J_wY J_wW d_w d_H d_0 d_k";
export var arrowContainer = "J_wZ d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "J_w0 J_wZ d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "J_w1 J_w0 J_wZ d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "J_w2 J_wZ d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "J_w3";
export var nextArrowContainerHidden = "J_w4 J_w2 J_wZ d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "J_kG d_0";
export var prevArrow = "J_w5 J_kG d_0";
export var nextArrow = "J_w6 J_kG d_0";
export var carouselIndicatorContainer = "J_w7 d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "J_w8 d_w d_bz d_bF";
export var carouselText = "J_w9 d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "J_xb J_w9 d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "J_xc d_b7";
export var carouselIndicator = "J_xd J_xc d_b7";
export var carouselIndicatorSelected = "J_xf J_xc d_b7";
export var arrowsContainerTopRight = "J_xg d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "J_xh d_0 d_bl d_bC";
export var arrowsContainerSides = "J_xj d_0 d_bl d_bC";
export var smallArrowsBase = "J_xk d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "J_xl J_xk d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "J_xm J_xl J_xk d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "J_xn J_xk d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "J_xp J_xn J_xk d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "J_n9";
export var iconSmall = "J_xq";
export var multipleWrapper = "J_xr d_bC d_bF d_bf";
export var multipleImage = "J_xs d_bC";
export var sidesPrevContainer = "J_xt J_xl J_xk d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "J_xv J_xl J_xk d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";